(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/campaign-flerp/views/campaign-flerp.js') >= 0) return;  svs.modules.push('/components/lb-ui/campaign-flerp/views/campaign-flerp.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.lb_ui=_svs.lb_ui||{};
_svs.lb_ui.campaign_flerp=_svs.lb_ui.campaign_flerp||{};
_svs.lb_ui.campaign_flerp.templates=_svs.lb_ui.campaign_flerp.templates||{};
svs.lb_ui.campaign_flerp.templates.campaign_flerp = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div id=\"campaign-flerp\" class=\"campaign-flerp-container\">\n    <div class=\"campaign-flerp "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLightMode") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":3,"column":31},"end":{"line":3,"column":157}}})) != null ? stack1 : "")
    + " f-500\">\n        <div class=\"campaign-flerp-text-container\">\n          <i class=\"icon icon-default icon-500 icon-game-package\"></i>\n          <span class=\"campaign-flerp-text f-left "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLoggedIn") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":6,"column":50},"end":{"line":6,"column":114}}})) != null ? stack1 : "")
    + "\">\n            Bli kund och få "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"reward") || (depth0 != null ? lookupProperty(depth0,"reward") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"reward","hash":{},"data":data,"loc":{"start":{"line":7,"column":28},"end":{"line":7,"column":38}}}) : helper)))
    + " kr i bonus."
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLoggedIn") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":50},"end":{"line":7,"column":119}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideLink") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":11,"column":19}}})) != null ? stack1 : "")
    + "          </span>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLoggedIn") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "campaign-flerp-light ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"branding") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":3,"column":71},"end":{"line":3,"column":150}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "campaign-flerp-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"branding") || (depth0 != null ? lookupProperty(depth0,"branding") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"branding","hash":{},"data":data,"loc":{"start":{"line":3,"column":106},"end":{"line":3,"column":118}}}) : helper)))
    + " ";
},"7":function(container,depth0,helpers,partials,data) {
    return "campaign-flerp-default ";
},"9":function(container,depth0,helpers,partials,data) {
    return "";
},"11":function(container,depth0,helpers,partials,data) {
    return "campaign-flerp-text-single-line";
},"13":function(container,depth0,helpers,partials,data) {
    return " Din bonus betalas ut vid avgjort sportspel.";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <a class=\"campaign-flerp-link f-medium\" href="
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"campaignUrl") || (depth0 != null ? lookupProperty(depth0,"campaignUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"campaignUrl","hash":{},"data":data,"loc":{"start":{"line":10,"column":59},"end":{"line":10,"column":76}}}) : helper))) != null ? stack1 : "")
    + ">Läs mer</a>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"campaign-step-container\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-lb_ui-campaign_step-campaign_step"),depth0,{"name":"components-lb_ui-campaign_step-campaign_step","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "          </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"campaignIsAvailable") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":21,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
Handlebars.partials['components-lb_ui-campaign_flerp-campaign_flerp'] = svs.lb_ui.campaign_flerp.templates.campaign_flerp;
})(svs, Handlebars);


 })(window);