(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/campaign-flerp/assets/javascripts/hbs-helpers.js') >= 0) return;  svs.modules.push('/components/lb-ui/campaign-flerp/assets/javascripts/hbs-helpers.js');
"use strict";

Handlebars.registerHelper('campaignFlerp', (branding, isLightMode, hideLink) => {
  var _svs$lb_ui$campaign_f, _svs$lb_ui, _svs$lb_ui2, _svs$lb_ui$campaign_f2, _svs$lb_ui3, _svs$lb_ui4;
  return svs.lb_ui.campaign_flerp.templates.campaign_flerp({
    campaignIsAvailable: (_svs$lb_ui$campaign_f = (_svs$lb_ui = svs.lb_ui) === null || _svs$lb_ui === void 0 || (_svs$lb_ui = _svs$lb_ui.campaign_flerp) === null || _svs$lb_ui === void 0 || (_svs$lb_ui = _svs$lb_ui.data) === null || _svs$lb_ui === void 0 || (_svs$lb_ui = _svs$lb_ui.campaignFlerp) === null || _svs$lb_ui === void 0 ? void 0 : _svs$lb_ui.campaignIsAvailable) !== null && _svs$lb_ui$campaign_f !== void 0 ? _svs$lb_ui$campaign_f : false,
    reward: (_svs$lb_ui2 = svs.lb_ui) === null || _svs$lb_ui2 === void 0 || (_svs$lb_ui2 = _svs$lb_ui2.campaign_flerp) === null || _svs$lb_ui2 === void 0 || (_svs$lb_ui2 = _svs$lb_ui2.data) === null || _svs$lb_ui2 === void 0 || (_svs$lb_ui2 = _svs$lb_ui2.campaignFlerp) === null || _svs$lb_ui2 === void 0 ? void 0 : _svs$lb_ui2.reward,
    isLightMode: isLightMode !== null && isLightMode !== void 0 ? isLightMode : false,
    campaignUrl: (_svs$lb_ui$campaign_f2 = (_svs$lb_ui3 = svs.lb_ui) === null || _svs$lb_ui3 === void 0 || (_svs$lb_ui3 = _svs$lb_ui3.campaign_flerp) === null || _svs$lb_ui3 === void 0 || (_svs$lb_ui3 = _svs$lb_ui3.data) === null || _svs$lb_ui3 === void 0 || (_svs$lb_ui3 = _svs$lb_ui3.campaignFlerp) === null || _svs$lb_ui3 === void 0 ? void 0 : _svs$lb_ui3.campaignUrl) !== null && _svs$lb_ui$campaign_f2 !== void 0 ? _svs$lb_ui$campaign_f2 : '',
    branding: branding !== null && branding !== void 0 ? branding : '',
    hideLink: hideLink !== null && hideLink !== void 0 ? hideLink : false,
    isLoggedIn: svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER),
    currentStepIndex: (_svs$lb_ui4 = svs.lb_ui) === null || _svs$lb_ui4 === void 0 || (_svs$lb_ui4 = _svs$lb_ui4.campaign_flerp) === null || _svs$lb_ui4 === void 0 || (_svs$lb_ui4 = _svs$lb_ui4.data) === null || _svs$lb_ui4 === void 0 || (_svs$lb_ui4 = _svs$lb_ui4.campaignFlerp) === null || _svs$lb_ui4 === void 0 ? void 0 : _svs$lb_ui4.currentStepIndex
  });
});

 })(window);